<template>
    <div class="pool">



        <div class="pool-top flex">
            <div class="pool-top-name flex">
                <div>{{ $t('pool.desc30') }}</div>
                <p>{{ $t('pool.desc31') }}</p>
            </div>
            <div class="pool-top-pledge flex" v-if="pledgeList.length > 0">
                <div class="pledge-name flex">{{ $t('pool.desc32') }} <img src="../../public/images/lefticon.png" alt="" />
                </div>
                <div class="pledge-btn"  @click="handlerShow">{{ $t('pool.desc33') }}</div>
            </div>
        </div>
        <div class="pool-mpbile-top flex">
            <div>{{ $t('pool.desc64') }}</div>
            <p class="flexcenter"  @click="handlerShow" v-if="pledgeList.length > 0">{{
                $t('pool.desc33') }}</p>
        </div>
        <div class="pool-profit flex">
            <div class="statistics-left">
                <div class="left-title flex">
                    <img src="../../public/images/daiicon.png" alt="" />{{ $t('pool.desc') }}
                </div>
                <div class="left-total">{{ common.getnum(poolinfo.rewardTotalValUsdt) || '0.00' }} USDT</div>
                <div class="left-info flex">
                    <div class="left-info-name">
                        <div>{{ $t('pool.desc1') }}</div>
                        <p>{{ common.getnum(poolinfo.pledgingValUsdt) || '0.00' }} USDT</p>
                    </div>
                    <div class="left-info-name">
                        <div>{{ $t('pool.desc2') }}</div>
                        <p>{{ poolinfo.pledgingCount || 0 }}</p>
                    </div>
                </div>
            </div>
            <div class="statistics-draw" v-for="item in poolinfo.incomeList">
                <div class="draw-title">{{ item.incomeName }}</div>
                <div class="draw-price">{{ common.getnum(item.incomeAmount) || '0.00' }}</div>
                <div class="draw-btn flexcenter" :class="{ grey: parseFloat(item.incomeAmount) == 0 }"
                    @click="handlerReceive(item.incomeType, item.incomeAmount)">{{
                        $t('pool.desc6') }}</div>
            </div>
            <!-- <div class="statistics-draw">
                <div class="draw-title">{{ $t('pool.desc4') }}</div>
                <div class="draw-price">{{ common.getnum(poolinfo.recRewardDc) || '0.00' }}</div>
                <div class="draw-btn flexcenter" :class="{ grey: parseFloat(poolinfo.recRewardDc) == 0 }"
                    @click="handlerReceive(1, poolinfo.recRewardDc)">{{ $t('pool.desc6')
                    }}</div>
            </div>
            <div class="statistics-draw">
                <div class="draw-title">{{ $t('pool.desc5') }}</div>
                <div class="draw-price">{{ common.getnum(poolinfo.communityRewardDc) || '0.00' }}</div>
                <div class="draw-btn flexcenter" :class="{ grey: parseFloat(poolinfo.communityRewardDc) == 0 }"
                    @click="handlerReceive(2, poolinfo.communityRewardDc)">{{
                        $t('pool.desc6') }}</div>
            </div>
            <div class="statistics-draw">
                <div class="draw-title">{{ $t('pool.desc71') }}</div>
                <div class="draw-price">{{ common.getnum(poolinfo.globalRewardDc) || '0.00' }}</div>
                <div class="draw-btn flexcenter" :class="{ grey: parseFloat(poolinfo.globalRewardDc) == 0 }"
                    @click="handlerReceive(3, poolinfo.globalRewardDc)">{{
                        $t('pool.desc6') }}</div>
            </div>
            <div class="statistics-draw">
                <div class="draw-title">{{ $t('pool.desc70') }}</div>
                <div class="draw-price">{{ common.getnum(poolinfo.reProfitIncomeRewardDc) || '0.00' }}</div>
                <div class="draw-btn flexcenter" :class="{ grey: parseFloat(poolinfo.reProfitIncomeRewardDc) == 0 }"
                    @click="handlerReceive(4, poolinfo.reProfitIncomeRewardDc)">{{
                        $t('pool.desc6') }}</div>
            </div> -->
        </div>

        <div class="pool-statistics">
            <div class="statistics-info flex">
                <div class="statistics-info-price">
                    <div class="flex">
                        <img src="../../public/images/daiicon.png" alt="" />{{ $t('pool.desc3') }}
                    </div>
                    <p>{{ topinfo.landWaitReceiveValUsdt || '0.00' }} USDT</p>
                </div>
                <div class="statistics-info-price">
                    <div class="flex">
                        <img src="../../public/images/daiicon.png" alt="" />{{ $t('pool.desc34') }}
                    </div>
                    <p>{{ topinfo.pledgingCount || '0.00' }}</p>
                </div>
                <div class="statistics-info-price">
                    <div class="flex">
                        <img src="../../public/images/daiicon.png" alt="" />{{ $t('pool.desc35') }}
                    </div>
                    <p>{{ topinfo.pledgingValUsdt || '0.00' }} USDT</p>
                </div>
            </div>
            <div class="statistics-list flex">
                <div class="list-info" v-for="item in topinfo.eraList" :key="item.id">
                    <div>{{ $t('pool.desc57', { n1: item.eraName }) }}</div>
                    <p>{{ item.landWaitReceiveValUsdt || '0.00' }} USDT</p>
                </div>
            </div>
        </div>
        <div class="pool-tabbar flex">
            <div :class="{ tabact: tabId == '' }">{{ $t('pool.desc36') }}</div>
           
        </div>
        <div class="planet-tabbar-select flex">
            <div class="planet-select">
                <div class="select-name">{{ $t('planet.desc') }}</div>
                <p><el-select v-model="eraId" :placeholder="$t('planet.desc1')" @change="onChange">
                        <el-option :label="item.name" :value="item.id" v-for="item in eraList" :key="item.id"></el-option>
                    </el-select></p>
            </div>
            <!-- <div class="planet-select">
                <div class="select-name">{{ $t('planet.desc3') }}</div>
                <p><el-select v-model="startTime" :placeholder="$t('planet.desc1')" @change="onChangeTime">
                        <el-option :label="item.name" :value="item.id" v-for="item in timeAll" :key="item.id"></el-option>
                    </el-select></p>
            </div> -->
            <div class="planet-select">
                <div class="select-name">{{ $t('planet.desc2') }}</div>
                <p><el-select v-model="status" :placeholder="$t('planet.desc1')" @change="onChangeStatus">
                        <el-option :label="item.name" :value="item.id" v-for="item in stausAll" :key="item.id"></el-option>
                    </el-select></p>
            </div>

        </div>
        <div class="pool-tabledata flex">
            <div class="tabledata-list flex" v-for="item in list" :key="item.id">
                <div class="list-left">
                    <img :src="item.landIcon" alt="" />
                    <!-- <div>{{ item.unSettleAmount }} DAOT</div>
                    <p>{{ $t('planet.desc5') }}</p> -->
                </div>
                <div class="list-info">
                    <div class="list-info-name">
                        <div>{{ $t('pool.desc38') }}</div>
                        <p>#{{ item.eraId }}-{{ item.planetId }}</p>
                    </div>
                    <div class="list-info-name">
                        <div>{{ $t('pool.desc39') }}</div>
                        <p>{{ item.eraName }}</p>
                    </div>
                    <div class="list-info-name">
                        <div>{{ $t('pool.desc40') }}</div>
                        <p>{{ item.startTime }}</p>
                    </div>
                    <div class="list-info-btn" @click="handlerInfo(item)" v-if="item.status != 2">{{ $t('record.desc15') }}</div>
                    <!-- <div class="list-info-name">
                        <div>{{ $t('pool.desc41') }}</div>
                        <p>{{ item.endTime }}</p>
                    </div> -->
                    <div class="list-info-btn" @click="showRedeem(item)" v-if="item.status == 2">{{ $t('pool.desc42') }}
                    </div>
                </div>
            </div>
        </div>
        <div class="planet-null" v-if="showNull && pledgeList.length ==0">
            <div class="planet-null-tips">{{ $t('planet.desc37') }}</div>
            <div class="planet-null-tips">{{ $t('planet.desc38') }}</div>
            <div class="planet-null-btn flex">
                <div class="flexcenter" @click="$router.push('/home')">{{ $t('planet.desc39') }}</div>
                <div class="flexcenter" @click="$router.push('/mining')"> {{ $t('home.desc4') }}</div>
            </div>
        </div>
        <div class="planet-null" v-if="showNull && pledgeList.length > 0">
            <div class="planet-null-tips">{{ $t('pool.desc72') }}</div>
            <div class="planet-null-tips">{{ $t('pool.desc73') }}</div>
            <div class="planet-null-btn flex">
                <div class="flexcenter" @click="$router.push('/home')">{{ $t('planet.desc39') }}</div>
                <div class="flexcenter" @click="handlerShow"> {{ $t('pool.desc74') }}</div>
            </div>
        </div>
        <div class="planet-page">
            <el-pagination layout="prev, pager, next" :pager-count="5" :page-size="6" :current-page="page" :total="total"
                @current-change="handlerCurrent" :hide-on-single-page="true"> </el-pagination>
        </div>
        <!-- 质押 -->
        <el-dialog title="提示" v-model="dialogVisible" width="30%" :lock-scroll="true" :append-to-body="true"
            class="modalinfo" :modal="true">
            <div class="modalinfo-con">
                <div class="modalinfo-con-title">
                    {{ $t('pool.desc52') }}
                    <img src="../../public/images/closemodal.png" alt="" @click="dialogVisible = false" />
                </div>
                <!-- <div class="backset-tips" v-html="$t('pool.desc53', { n1: poolName })"></div> -->
                <div class="pledge-list">
                    <div class="pledge-list-con flex" v-for="(item, index) in pledgeList" :key="item.id">
                        <img :src="item.icon" alt="" class="pledge-img" />
                        <div class="pledge-info flex">
                            <div class="pledge-info-item">
                                <div>{{ $t('planet.desc43') }}</div>
                                <p>#{{ item.landId }}</p>
                            </div>
                            <div class="pledge-info-item">
                                <div>{{ $t('pool.desc56') }}</div>
                                <p>{{ item.surplusSettleAmountDaot }} DAOT</p>
                            </div>
                        </div>
                        <div class="select-btn" :class="{ actpledge: item.ischeck }" @click="handlerImg(index)">
                            <img src="../../public/images/selectbtn.png" alt="" v-if="item.ischeck" />
                        </div>
                    </div>
                </div>
                <div class="modalinfo-con-btn flex">
                    <div class="flexcenter" @click="handlerSomePledge(0)">{{ $t('pool.desc54') }}</div>
                    <div class="flexcenter" @click="handlerSomePledge(1)">{{ $t('pool.desc55') }}</div>
                </div>
            </div>
        </el-dialog>
        <!-- 赎回 -->
        <el-dialog title="提示" v-model="dialogRedeem" width="30%" :lock-scroll="true" :append-to-body="true"
            class="modalinfo" :modal="false">
            <div class="modalinfo-con">
                <div class="modalinfo-con-title">
                    {{ $t('pool.desc60') }}
                    <img src="../../public/images/closemodal.png" alt="" @click="dialogRedeem = false" />
                </div>
                <div class="backset-tips"
                    v-html="$t('pool.desc61', { n1: redeemInfo.priceAmount, n2: 'DAOT', n3: redeemInfo.eraId, n4: redeemInfo.planetId })">
                </div>
                <div class="redeem-con flex">
                    <img :src="redeemInfo.landIcon" alt="" />
                    <div class="redeem-info">
                        <div>#{{ redeemInfo.eraId }}_{{ redeemInfo.planetId }}</div>
                        <p>x1</p>
                    </div>
                </div>
                <div class="modalinfo-con-btn flex">
                    <div class="flexcenter" @click="dialogRedeem = false">{{ $t('planet.desc31') }}</div>
                    <div class="flexcenter" @click="handlerRedeem">{{ $t('pool.desc62') }}</div>
                </div>
            </div>
        </el-dialog>
          <!-- 星球详情 -->
          <starDetails v-if="isStarDetails" :planetId="starDetailsId" :callback="closeFun"></starDetails>
    </div>
</template>

<script>
import starDetails from '@/componets/starDetails.vue'
export default {
    components:{
        starDetails
    },
    data() {
        return {
            isStarDetails:false,
            starDetailsId: '',
            dialogRedeem: false,
            showNull: false,
            dialogVisible: false,
            tabId: '',
            type: '',
            page: 1,
            eraId: '',
            startTime: '',
            status: '',
            eraList: [{
                id: '',
                name: this.$t('planet.desc40')
            }],
            timeAll: [
                {
                    id: '',
                    name: this.$t('planet.desc40')
                },
                {
                    id: 7,
                    name: this.$t('pool.desc46')
                }, {
                    id: 15,
                    name: this.$t('pool.desc47')
                }, {
                    id: 30,
                    name: this.$t('pool.desc48')
                }, {
                    id: 90,
                    name: this.$t('pool.desc49')
                }, {
                    id: 180,
                    name: this.$t('pool.desc50')
                }, {
                    id: 365,
                    name: this.$t('pool.desc51')
                },
            ],
            timelist: [],
            stausAll: [
                {
                    id: '',
                    name: this.$t('planet.desc40')
                },
                {
                    id: 1,
                    name: this.$t('pool.desc43')
                },
                {
                    id: 2,
                    name: this.$t('pool.desc44')
                },
                {
                    id: 3,
                    name: this.$t('pool.desc45')
                }
            ],
            total: 0,
            list: [],
            pledgeList: [],
            topinfo: {},
            isBtn: true,
            poolName: '',
            poolId: '',
            redeemInfo: {},
            poolinfo: {}
        }
    },
    mounted() {
        this.poolId = '';
        this.tabId = this.poolId;
        this.$post(this.URL.planet.eralist, {}).then(res => {
            if (res.code == 0) {
                this.eraList = this.eraList.concat(res.data);
            } else { this.$message.error(res.message) }
        })
        this.$post(this.URL.pool.list, {
            page: 1,
            pageSize: 50
        }).then(res => {
            if (res.code == 0) {
                this.timelist = res.data.list;
                this.poolId=this.timelist[0].poolId;
               

            } else if (res.code != 401) {
                this.$message.error(res.message)
            }
        })
        this.getpledgeList();
        this.getlist()
        this.getreceive();
    },
    methods: {
        closeFun() {
            this.isStarDetails = false;
        },
        handlerInfo(info) {
            this.starDetailsId = info.planetId;
          
            this.isStarDetails = true;
        },
        getreceive() {
            this.$post(this.URL.pool.overview, {}).then(res => {
                if (res.code == 0) {
                    this.poolinfo = res.data;
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        handlerReceive(i, num) {
            if (parseFloat(num) > 0) {
                this.$post(this.URL.pool.receive, {accountType:i}).then(res => {
                    if (res.code == 0) {
                        this.$message.success(this.$t('pool.desc65'))
                        this.getreceive();
                    } else {
                        this.$message.error(res.message)
                    }
                })
            }
        },
        //赎回
        showRedeem(info) {
            this.redeemInfo = info;
            this.dialogRedeem = true;
        },
        handlerRedeem() {
            this.$post(this.URL.pool.redeem, {
                recordId: this.redeemInfo.recordId
            }).then(res => {
                if (res.code == 0) {
                    this.$message.success(this.$t('pool.desc63'));
                    this.dialogRedeem = false;
                    this.page = 1;
                    this.getlist();
                } else if (res.code != 401) {
                    this.$message.error(res.message)
                }
            })
        },
        //获取天数
        GetDateStr(AddDayCount) {
            var dd = new Date();
            dd.setDate(dd.getDate() - AddDayCount);//获取AddDayCount天后的日期

            var y = dd.getFullYear();

            var m = (dd.getMonth() + 1) < 10 ? "0" + (dd.getMonth() + 1) : (dd.getMonth() + 1);//获取当前月份的日期，不足10补0

            var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();//获取当前几号，不足10补0

            return y + "-" + m + "-" + d;

        },
        //选中质押
        handlerShow() {
        
            this.pledgeList.forEach(e => {
                e.ischeck = false;
            })
            this.dialogVisible = true;
            this.isBtn = true;
        },
        handlerImg(i) {
            this.pledgeList[i].ischeck ? this.pledgeList[i].ischeck = false : this.pledgeList[i].ischeck = true;
        },
        getpledgeList() {
            this.$post(this.URL.pool.ablepledge, {}).then(res => {
                if (res.code == 0) {
                    this.pledgeList = res.data;
                    this.pledgeList.forEach(e => {
                        e.ischeck = false;
                    })
                } else if (res.code != 401) {
                    this.$message.error(res.message)
                }
            })
            this.$post(this.URL.pool.infooverview, {}).then(res => {
                if (res.code == 0) {
                    this.topinfo = res.data;
                } else if (res.code != 401) {
                    this.$message.error(res.message)
                }
            })
        },
        onChange(val) {
            this.page = 1;
            this.getlist()
        },
        onChangeStatus(val) {
            this.page = 1;
            this.getlist()
        },
        onChangeTime(val) {
            this.page = 1;
            this.getlist()
        },
        handlerCurrent(val) {
            this.list = [];
            this.page = val;
            this.getlist();
        },
        handlerSomePledge(i) {
            let selectId = [], selectId1 = [];
            this.pledgeList.forEach(e => {
                selectId1.push(e.landId)
                if (e.ischeck) {
                    selectId.push(e.landId)
                }
            })
            if (i == 0 && selectId.length == 0) {
                this.$message.error(this.$t('pool.desc58'));
                return;
            }
            if (this.isBtn) {
                this.isBtn = false;
                this.$post(this.URL.pool.deposit, {
                    poolId: this.poolId,
                    landIds: i == 0 ? selectId : selectId1
                }).then(res => {
                    this.isBtn = true;
                    if (res.code == 0) {
                        this.$message.success(this.$t('pool.desc59'));
                        this.dialogVisible = false;
                        this.page = 1;
                        this.getpledgeList();
                        this.getlist();
                    } else if (res.code != 401) {
                        this.$message.error(res.message)
                    }
                })
            }
        },
        getlist() {
            let startTime = '';
            this.showNull = false;
            this.startTime == '' ? startTime = '' : startTime = this.GetDateStr(this.startTime);
         
            this.$post(this.URL.pool.record, {
                page: this.page,
                pageSize: 6,
                eraId: this.eraId,
                startTime: startTime,
                status: this.status,
                poolId: this.poolId
            }).then(res => {
                if (res.code == 0) {
                    this.list = res.data.list;
                    this.total = parseFloat(res.data.page.count);
                    if (this.total == 0) {
                        this.showNull = true;
                    }
                } else if (res.code != 401) {
                    this.$message.error(res.message)
                }
            })
        },
        //切换
        handlerTab(id) {
            this.tabId = id;
            this.poolId = id;
            let info = this.timelist.find(item => item.poolId == this.poolId)
            if (id != '') {
                this.poolName = this.$t('pool.desc37', { n1: info.contractDays })
            }
            this.page = 1;
            this.list = [];
            this.getlist();
        }
    }
}
</script>

<style lang="less" scoped>
.pool {
    width: 1240px;
    padding: 96px 0 0;
    margin: 0 auto;

    .pool-profit {
        margin-bottom: 24px;

        .statistics-left {
            flex: 0 0 330px;
            width: 388px;
            padding: 28px 0;
            background: linear-gradient(180deg, rgba(255, 226, 127, 0.3) -10.71%, rgba(110, 85, 38, 0.3) 84.58%);


            .left-title {
                margin-bottom: 15px;
                padding: 0 32px;
                font-size: 20px;
                color: #FFFFFF;
                line-height: 20px;

                img {
                    flex: 0 0 14px;
                    width: 14px;
                    height: 14px;
                    margin: 3px 4px 0 0;
                }
            }

            .left-total {
                padding: 0 32px;
                font-size: 30px;
                color: #FFFFFF;
            }

            .left-info {
                padding: 28px 32px 0 13px;

                .left-info-name {
                    position: relative;
                    flex: 0 0 50%;

                    &:first-child ::after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        right: 0;
                        width: 1px;
                        height: 28px;
                        background: rgba(255, 255, 255, 0.4);
                        transform: translateY(-50%);
                    }

                    div {
                        padding-left: 19px;
                        font-size: 13px;
                        color: #A8A8A8;
                    }

                    p {
                        padding: 10px 0 0 19px;
                        font-size: 16px;
                        color: #FFFFFF;
                    }
                }
            }
        }

        .statistics-draw {
            flex: 0 0 169px;
            padding: 28px 0;
            background: linear-gradient(180deg, rgba(255, 226, 127, 0.3) -10.71%, rgba(110, 85, 38, 0.3) 84.58%);
            margin-left: 13px;

            .draw-title {
                margin-bottom: 15px;
                padding: 0 24px;
                font-size: 18px;
                color: #FFFFFF;
                line-height: 20px;
            }

            .draw-price {
                padding: 0 24px;
                font-size: 24px;
                color: #FFFFFF;
            }

            .draw-btn {
                margin: 28px auto 0;
                width: 142px;
                height: 40px;
                background: #FFE279;
                font-size: 14px;
                color: #111111;
                cursor: pointer;
            }

            .grey {
                background: #444444;
            }
        }
    }

    .pool-top {
        padding: 20px 0 15px;

        .pool-top-name {
            flex: 0 0 40%;
            font-size: 13px;
            color: #A8A8A8;
            line-height: 49px;

            div {
                margin-right: 25px;
                font-size: 24px;
                color: #FFFFFF;
            }
        }

        .pool-top-pledge {
            flex: 0 0 60%;
            justify-content: flex-end;

            .pledge-name {
                font-size: 13px;
                color: #A8A8A8;
                padding-top: 14px;

                img {
                    width: 17px;
                    height: 6px;
                    margin: 7px 0 0 6px;
                }
            }

            .pledge-btn {
                width: 155px;
                height: 49px;
                margin-left: 40px;
                background: url('../../public/images/planetbtnbg.png') center no-repeat;
                background-size: 100% 100%;
                font-size: 18px;
                color: #000000;
                cursor: pointer;
                text-align: center;
                line-height: 48px;
            }

            .grey {
                opacity: .7;
            }
        }
    }

    .pool-mpbile-top {
        display: none;
    }

    .pool-statistics {
        padding: 24px;
        background: rgba(255, 226, 127, 0.3);

        .statistics-info {
            padding-bottom: 28px;
            border-bottom: 1px solid rgba(238, 238, 238, 0.2);

            .statistics-info-price {
                margin-right: 100px;

                &:last-child {
                    margin-right: 0;
                }

                div {

                    font-size: 16px;
                    color: #FFFFFF;

                    img {
                        width: 14px;
                        height: 14px;
                        margin: 6px 4px 0 0;
                    }
                }

                p {
                    margin-top: 10px;
                    font-size: 24px;
                    color: #FFFFFF;
                    font-weight: 600;
                }
            }
        }

        .statistics-list {
            margin-top: 24px;
            justify-content: space-between;

            .list-info {
                font-size: 13px;
                color: #A8A8A8;

                p {
                    margin-top: 10px;
                    font-size: 20px;
                    color: #FFFFFF;
                }
            }
        }
    }

    .pool-tabbar {
        padding: 20px 0 2px;

        div {
            margin-right: 34px;
            font-size: 24px;
            color: #BBBBBB;
            cursor: pointer;

            &:last-child {
                margin-right: 0;
            }

            &:hover {
                position: relative;
                color: #FFFFFF;

             
            }
        }

        .tabact {
            position: relative;
            color: #FFFFFF;

         
        }
    }

    .planet-tabbar-select {
        margin: 30px 0 24px;

        .planet-select {
            flex: 0 0 160px;
            margin-right: 24px;

            div {
                margin-bottom: 10px;
                font-size: 13px;
                color: #A8A8A8;
            }

            ::v-deep .el-select {
                width: 100%;
                height: 40px;

                .el-input__wrapper {
                    height: 40px;
                    background-color: rgba(0, 0, 0, 0.04);
                    border: 1px solid rgba(255, 255, 255, .2) !important;
                    box-shadow: 0 0 1px rgba(255, 255, 255, 1) inset !important;
                    box-sizing: border-box;
                    border-radius: 0;

                    .el-input__inner {
                        font-size: 16px;
                        color: #FFFFFF;
                        font-family: 'BaiJamjuree-Medium';
                    }

                    .el-input__suffix-inner {

                        i {
                            position: relative;

                            &::after {
                                content: '';
                                position: absolute;
                                top: 50%;
                                right: 0;
                                width: 10px;
                                height: 6px;
                                background: url('../../public/images/downicon.png') center no-repeat;
                                background-size: 100% 100%;
                                transform: translateY(-50%);
                            }

                            svg {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        .planet-type {
            margin-top: 27px;
            padding: 0 25px;
            background: #222222;
            height: 40px;
            font-size: 13px;
            color: #FFFFFF;
            cursor: pointer;

            img {
                width: 14px;
                height: 14px;
                margin-right: 6px;
            }
        }
    }

    .pool-tabledata {
        flex-wrap: wrap;

        .tabledata-list {
            flex: 0 0 404px;
            width: 404px;
            padding: 24px 0;
            background: linear-gradient(180deg, rgba(255, 226, 127, 0.3) -10.71%, rgba(110, 85, 38, 0.3) 84.58%);
            margin: 0 14px 14px 0;
            border-radius: 16px;

            &:nth-child(3n) {
                margin-right: 0;
            }

            .list-left {
                flex: 0 0 224px;
                font-size: 13px;
                margin-right: 24px;
                color: #A8A8A8;

                img {
                    width: 200px;
                    height: 200px;
                    margin: 0 0 6px 24px;
                    object-fit: cover;
                }

                div,
                p {
                    padding-left: 24px;
                }

                div {
                    margin: 10px 0;
                    font-size: 18px;
                    color: #FFFFFF;
                    font-weight: 600;
                }
            }

            .list-info {
                flex: 1;
                overflow: hidden;

                .list-info-name {
                    margin-top: 10px;
                    font-size: 12px;
                    color: #A8A8A8;

                    &:first-child {
                        margin-top: 0;
                    }

                    p {
                        margin-top: 10px;
                        font-size: 14px;
                        color: #FFFFFF;
                    }
                }

                .list-info-btn {
                    margin-top: 16px;
                    width: 102px;
                    height: 37px;
                    background: url('../../public/images/planetbtnbg.png') center no-repeat;
                    background-size: 100% 100%;
                    font-size: 16px;
                    color: #000000;
                    text-align: center;
                    line-height: 37px;
                    cursor: pointer;
                }
            }
        }
    }

    .planet-null {
        padding: 100px 0 0 0;

        .planet-null-tips {
            font-size: 30px;
            color: #FFFFFF;
            font-weight: bold;
            line-height: 45px;
        }

        .planet-null-btn {
            margin-top: 64px;

            div {
                flex: 0 0 221px;
                height: 63px;
                margin-right: 16px;
                font-size: 16px;
                color: #FFFFFF;
                background: url('../../public/images/planentbtnbg2.png') center no-repeat;
                background-size: 100% 100%;
                cursor: pointer;

                &:last-child {
                    margin-right: 0;
                    color: #000000;
                    background: url('../../public/images/planetbtnbg.png') center no-repeat;
                    background-size: 100% 100%;

                }
            }
        }
    }

    .planet-page {
        padding: 40px 0;

        ::v-deep .el-pagination {
            button {
                background-color: rgba(255, 255, 255, 0);
                color: #666666;
                font-size: 14px;
            }

            .el-pager {
                li {
                    background: rgba(255, 255, 255, 0);
                    font-size: 14px;
                    color: #666666;
                }

                .is-active {
                    background: #FFE279 !important;
                    border-radius: 4px !important;
                    color: #000000 !important;
                }
            }

        }
    }
}

@media (max-width:1200px) {
    .pool {
        padding: 85px 15px 20px;
        width: 700px;

        .pool-profit {
            flex-wrap: wrap;
            margin-bottom: 8px;

            .statistics-left {
                flex: 0 0 100%;
                margin-bottom: 16px;
                padding: 24px 0;

                .left-title {
                    padding: 0 16px;
                    font-size: 16px;
                }

                .left-total {
                    padding: 0 16px;
                    font-size: 24px;
                }

                .left-info {
                    padding: 16px 16px 0;

                    .left-info-name {
                        flex: 0 0 auto;

                        &:first-child {
                            margin-right: 27px;
                            padding-right: 27px;
                        }

                        div,
                        p {
                            padding-left: 0;
                        }
                    }
                }
            }

            .statistics-draw {
                flex: 0 0 48%;
                margin-bottom: 9px;
                margin-left: 0;
                padding: 16px 0;

                &:nth-child(2n) {
                    margin-right: 4%;
                }


                .draw-title {
                    padding: 0 16px;
                    margin-bottom: 10px;
                    font-size: 12px;
                }

                .draw-price {
                    padding: 0 16px;
                    font-size: 24px;
                    margin-bottom: 16px;
                }

                .draw-btn {
                    margin: 0 0 0 16px;
                    width: 88px;
                    height: 30px;
                    font-size: 14px;
                }
            }
        }

        .pool-top {
            display: none;
        }

        .pool-mpbile-top {
            display: flex;
            padding: 16px 0;

            div {
                flex: 1;
                font-size: 24px;
                color: #fff;
            }

            p {
                flex: 0 0 102px;
                height: 37px;
                background: url('../../public/images/planetbtnbg.png') center no-repeat;
                background-size: 100% 100%;
            }
        }

        .pool-statistics {
            padding: 16px;

            .statistics-info {
                flex-wrap: wrap;
                padding-bottom: 24px;

                .statistics-info-price {
                    flex: 0 0 50%;
                    margin-right: 0;

                    div {
                        font-size: 12px;
                        line-height: 14px;

                        img {
                            margin-top: 1px;
                        }
                    }

                    p {
                        font-size: 18px;
                    }

                    &:last-child {
                        margin-top: 22px;
                    }
                }

            }

            .statistics-list {
                flex-wrap: wrap;
                justify-content: left;

                .list-info {
                    flex: 0 0 33.3%;
                    width: 33.3%;
                    margin-top: 24px;
                    font-size: 12px;

                    &:nth-child(-n + 3) {
                        margin-top: 0;
                    }

                    p {
                        font-size: 14px;
                    }
                }
            }
        }

        .pool-tabbar {
            width: 100%;
            overflow-x: scroll;

            &::-webkit-scrollbar {
                width: 5px;
                height: 5px;
                background-color: rgba(0, 0, 0, 0.2);
            }

            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                border-radius: 10px;
                background-color: rgba(0, 0, 0, 0);
            }

            /*定义滑块
     内阴影+圆角*/
            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                background-color: rgba(255, 255, 255, 0.2);
            }

            div {
                flex: 0 0 auto;
            }
        }

        .planet-tabbar-select {
            .planet-select {
                flex: 0 0 15%;
                margin-right: 15px;

                ::v-deep .el-select {
                    width: 100%;
                    height: 40px;

                    .el-input__wrapper {
                        position: relative;
                        height: 40px;
                        padding: 0;
                        background-color: rgba(0, 0, 0, 0.04);
                        border: 1px solid rgba(255, 255, 255, 0) !important;
                        box-shadow: 0 0 1px rgba(255, 255, 255, 0) inset !important;

                        .el-input__inner {
                            font-size: 14px;
                            padding: 0;
                            width: 100%;
                        }

                        .el-input__suffix {
                            position: absolute;
                            top: 0;
                            right: 0;

                            i {
                                position: relative;

                                &::after {
                                    content: '';
                                    position: absolute;
                                    top: 50%;
                                    right: 0;
                                    width: 10px;
                                    height: 6px;
                                    background: url('../../public/images/downicon.png') center no-repeat;
                                    background-size: 100% 100%;
                                    transform: translateY(-50%);
                                }

                            }
                        }

                    }
                }
            }
        }

        .pool-tabledata {
            .tabledata-list {
                flex: 0 0 100%;
                margin-right: 0;
            }
        }

        .planet-null {
            padding: 50px 0 0;

            .planet-null-tips {
                font-size: 16px;
                line-height: 25px;
            }

            .planet-null-btn {
                margin-top: 32px;

                div {
                    flex: 0 0 111px;
                    height: 40px;
                    font-size: 14px;
                }
            }
        }
    }
}

@media (max-width:767px) {
    .pool {
        width: auto;

        .pool-tabledata {
            .tabledata-list {
                flex: 0 0 100%;
                margin-right: 0;
                padding: 16px 0;

                .list-left {
                    flex: 0 0 158px;
                    width: 158px;
                    margin: 24px 16px 0 0;

                    img {
                        width: 142px;
                        height: 142px;
                        margin: 0 0 6px 16px;
                        object-fit: cover;
                    }

                    div,
                    p {
                        font-size: 12px;
                        padding-left: 16px;
                    }

                    div {
                        font-size: 16px;
                    }
                }

                .list-info {
                    .list-info-name {
                        margin-top: 10px;
                    }
                }
            }
        }

        .planet-tabbar-select {
            justify-content: space-between;

            .planet-select {
                flex: 0 0 30%;
                margin-right: 0;

            }
        }
    }
}
</style>